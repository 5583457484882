/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:556f034a-c3dc-4732-8721-27e5e43aea79",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YZMV6bl3G",
    "aws_user_pools_web_client_id": "45q1e3h2fnctu45ns619gbulho",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://f2g2wkqadncohmqglgw2yldqoq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2wsbbvavnnbtjc55s2nyloht6u"
};


export default awsmobile;
